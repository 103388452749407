<template>
  <div class="bgWrapper">

    <div 
      class="overlay"
      :style="optionsAreActive
              ? { backdropFilter : 'blur(15px)'}
              : {}"
    >
      <div class="optionsContainer">
        <options
          :bg='activeBackground'
          @updateBackground='updateBackground'
          v-if="optionsAreActive"
        />
      </div>
      
      <div class="toolBox">
        <div class="toolButtons row">
          <button 
            class="newBG"
            @click="optionsAreActive = true"
          >
            new 
          </button>
        </div>


        <color-tools 
          :bg='activeBackground'
          @updateBackground='updateBackground'
          v-if="activeBackground.type === 'color'"
        />

        <pattern-tools 
          :bg='activeBackground'
          v-if="activeBackground.type === 'pattern'"
        />

        <image-tools
          :bg='activeBackground'
          v-if="activeBackground.type === 'image'"
        />
      </div>
    </div>

    <div 
      class="preview"
      :style="backgroundPreview"
    >
    </div>
  </div>
</template>

<script>
import ColorTools from './colorTools.vue';
import PatternTools from './patternTools.vue';
import ImageTools from './imageTools.vue';
import Options from './bgOptions.vue';

export default {
  components: {
    ColorTools,
    PatternTools,
    ImageTools,
    Options
  },





  data() {
    return {
      optionsAreActive: false,

      activeBackground: {
        type: 'pattern',
        src: {
          name: 'hex_1',
          height: '85',
          width: '73.612',
          pathColorType: 'fill',
          paths: [
            'M36.8-18.62L47.57 0 58.4-18.6zM47.57 0L36.7 18.62h21.6zM0 2.63l10.75 18.62L21.59 2.64zm10.75 18.62L-.1 39.87h21.6zM73.61 2.63l10.75 18.62L95.21 2.64zm10.75 18.62L73.52 39.87h21.6zm-47.55 2.63L47.56 42.5 58.4 23.9zM47.56 42.5L36.7 61.12h21.6zM0 45.13l10.75 18.62 10.84-18.61zm10.75 18.62L-.1 82.37h21.6zm62.86-18.62l10.75 18.62 10.85-18.61zm10.75 18.62L73.52 82.37h21.6zm-47.55 2.63L47.56 85 58.4 66.4zM47.56 85L36.7 103.62h21.6z',

            'M58.4-18.61L47.56 0h21.59L58.4-18.6zM47.56 0h-21.6l10.75 18.62L47.56 0zM21.59 2.64L10.75 21.25h21.6L21.58 2.65zM10.75 21.25h-21.6L-.1 39.87l10.85-18.62zm52.02 0l10.75 18.62 10.84-18.62h-21.6zm-4.37 2.64L47.56 42.5h21.59L58.4 23.9zM47.56 42.5h-21.6l10.75 18.62L47.56 42.5zm-25.97 2.64L10.75 63.75h21.6L21.58 45.15zM10.75 63.75h-21.6L-.1 82.37l10.85-18.62zm52.02 0l10.75 18.62 10.84-18.62h-21.6zm-4.37 2.64L47.56 85h21.59L58.4 66.4zM47.56 85h-21.6l10.75 18.62L47.56 85z',

            'M58.3 103.62l10.85-18.61h-21.6zM25.97 85h21.6L36.8 66.38zm32.35-66.38L69.15.01h-21.6zM25.96 0h21.6L36.8-18.62zm36.8 63.75h21.6L73.61 45.13zM21.5 39.87l10.84-18.61H10.75zm-32.34-18.62h21.59L0 2.63zm95.2 0L73.61 2.63 62.77 21.25zm-73.61 42.5L21.5 82.37l10.84-18.61zM0 45.13l-10.85 18.62h21.6zm10.33 20.45l-.37-.56-.15.26zM46.1 86.23l.52.3.15-.26zm23.05-43.72h-21.6l10.76 18.61zm-21.6 0L36.82 23.87 25.96 42.5z'
          ],
          scale: '2',
          rotate: '0',
          opacity: '1'
        },
        colors: [
          // '#7BF1A8',
          '#FFFFFF',
          '#97F9F9',
          '#7189FF',
          '#5E239D',
          '#FF66B3',
        ]
      },

      bgTypes: [
        {
          type: 'color',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 22C6.49 22 2 17.51 2 12S6.49 2 12 2s10 4.04 10 9c0 3.31-2.69 6-6 6h-1.77c-.28 0-.5.22-.5.5 0 .12.05.23.13.33.41.47.64 1.06.64 1.67 0 1.38-1.12 2.5-2.5 2.5zm0-18c-4.41 0-8 3.59-8 8s3.59 8 8 8c.28 0 .5-.22.5-.5 0-.16-.08-.28-.14-.35-.41-.46-.63-1.05-.63-1.65 0-1.38 1.12-2.5 2.5-2.5H16c2.21 0 4-1.79 4-4 0-3.86-3.59-7-8-7z"/><circle cx="6.5" cy="11.5" r="1.5"/><circle cx="9.5" cy="7.5" r="1.5"/><circle cx="14.5" cy="7.5" r="1.5"/><circle cx="17.5" cy="11.5" r="1.5"/></svg>'
        },
        {
          type: 'image',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/></svg>'
        },
        {
          type: 'pattern',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z"/></svg>'
        }
      ],
      
      // Patterns:
      // src: pathColorType, paths

      // Colors:
      // BorderType
    }
  },





  computed: {
    backgroundPreview() {
      var type = this.activeBackground.type,
          colors = this.activeBackground.colors,
          src = this.activeBackground.src,
          bgStyles;

      switch (type) {
        case 'pattern':
          var paths = this.buildPaths(src),
              svg = "<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><defs><pattern id='bgPattern' patternUnits='userSpaceOnUse' width='" + src.width + "' height='" + src.height + "' patternTransform='scale(" + src.scale + ") rotate(" + src.rotate + ")'><rect x='0' y='0' width='100%' height='100%' fill='" + colors[0] + "'/>" + paths + "</pattern></defs><rect width='800%' height='800%' fill='url(%23bgPattern)'/></svg>";

          bgStyles = { background : this.SVGtoURI(svg) };
          break;

        case 'image':
          bgStyles = { backgroundImage : `url(${src.path})`,
                       backgroundSize : 'cover',
                       backgroundPosition: 'center',
                       backgroundRepeat: 'no-repeat' };
          break;

        case 'color':

          bgStyles = { background : src.css };
          break;
      }

      return bgStyles;
    },
  },





  methods: {
    updateBackground(src, type) {
      console.log(typeof src);

      this.activeBackground.src = src;
      this.activeBackground.type = type;
      this.optionsAreActive = false;
    },



    buildBG() {
      // var pattern = this.activeBackground.type === 'pattern'
      //               ? this.activeBackground.src
      //               : '',
      //     paths = this.buildPaths(pattern),
      //     svg = "<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><defs><pattern id='bgPattern' patternUnits='userSpaceOnUse' width='" + pattern.width + "' height='" + pattern.height + "' patternTransform='scale(" + pattern.scale + ") rotate(" + pattern.rotate + ")'><rect x='0' y='0' width='100%' height='100%' fill='" + this.activeBackground.colors[0] + "'/>" + paths + "</pattern></defs><rect width='800%' height='800%' fill='url(%23bgPattern)'/></svg>",
      //     encodedSvg = svg.replace(/#/g, '%23'),
      //     uri = `url('data:image/svg+xml, ${encodedSvg}')`;

      // return uri;
    },



    buildPaths(pattern) {
      var paths = '';

      for (var i = 0; i < pattern.paths.length; i++) {
        var p = pattern.paths[i],
            pcType = pattern.pathColorType,
            pathC = pcType === 'stroke'
                    ? "stroke='" + this.activeBackground.colors[1 + i] + "' fill='none'"
                    : "stroke='none' fill='" + this.activeBackground.colors[i + 1] + "' ",
            path = "<path d='" + p + "' stroke-width='" + pattern.strokeWidth + "' " + pathC + " opacity='" + pattern.opacity + "' />";

        paths += path;
      }

      return paths;
    },



    SVGtoURI(svg) {
      var encodedSvg = svg.replace(/#/g, '%23'),
          uri = `url("data:image/svg+xml,${encodedSvg}")`;
      
      return uri;
    },


    
    rnd(x) {
      return Math.round(x * 100) / 100;
    },



    limitNumberWithinRange(val, min, max) {
      return Math.min(Math.max(val, min), max);
    },



    
    // Converts colors in hexidecimal format into RGB.
    hexToRGB(hexColor) {
      var R = 0,
          G = 0,
          B = 0;

      // Test for 3-digit hex format.
      if (hexColor.length == 4) {
        R = '0x' + hexColor[1] + hexColor[1]; 
        G = '0x' + hexColor[2] + hexColor[2];
        B = '0x' + hexColor[3] + hexColor[3];
      }
      // Else 6-digit.
      else if (hexColor.length == 7) {
        R = '0x' + hexColor[1] + hexColor[2]; 
        G = '0x' + hexColor[3] + hexColor[4];
        B = '0x' + hexColor[5] + hexColor[6];
      }

      // Use unary plus operator to convert to number.
      return 'rgb(' + +R + ',' + +G + ',' + +B + ')';
    },



    // Takes color in rgb format and returns brightness.
    // Based on http://www.w3.org/TR/AERT#color-contrast
    // A brightness difference under 125 between text and background is insufficient.
    getBrightness(color) {
      var hexRgx = /^#([0-9A-F]{3}){1,2}$/i,
          splitChar,
          rgbArr,
          brightness;

      if (hexRgx.test(color)) {
        color = this.hexToRGB(color);
      }

      splitChar = color.indexOf(',') > -1 // If comma detected,
                  ? ',' // split RGB at comma.
                  : ''; // Else, split RGB at whitespace.

      // Turn rgb(r,g,b) into [r, g, b].
      rgbArr = color.substr(4).split(')')[0].split(splitChar);

      brightness = Math.round(((parseInt(rgbArr[0]) * 299) + (parseInt(rgbArr[1]) * 587) + (parseInt(rgbArr[2]) * 114)) / 1000);

      return brightness;
    },
  },





  created() {
  
  }
}
</script>

<style>
  .bgWrapper,
  .overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  /* .toolbar {
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 10;
  } */

  .optionsContainer {
    width: 100%;
    height: 100%;
  }

  .toolBox {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 22rem;
    width: 32rem;
    background: #fff;
    padding: 1rem;
    border-radius: .25rem .25rem 0 0;
    margin: auto;
    box-shadow: 0 0 3px 1px #888;
  }

  .preview {
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  .toolButtons {
    padding-bottom: 1rem;
  }

  .row {
    display: flex;
    align-items: center;
  }

  input[type='radio'] {
    position: absolute;
    max-height: 0;
    max-width: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }

  input[type='radio']:checked + label {
    opacity: 1;
  }

  .colorInput {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    border: none;
    /* border-radius: 3px; */
    /* box-shadow: 0px .25px 2.25px .75px #aaa; */
    /* transition: all .2s ease; */
  }

  /* .colorInput:hover  */

  .colorInput input[type='color'] {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    border: none;
    border-radius: 3px;
    /* box-shadow: 0px 0px 2.25px .5px #ccc; */
    box-shadow: 0px 0px 2px .25px #aaa;
    margin: 0;
    cursor: pointer;
    transform-origin: center;
    transition: all .2s ease;
  }

  .colorInput:hover input[type='color'] {
    box-shadow: 0px 0.75px 2.75px 0.25px #aaa;
    transform: scale(1.1);
  }
 
 .colorInput input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }
 
 .colorInput input[type='color']::-webkit-color-swatch {
    border: none;
    border-radius: 3px;
  }


</style>

<template>
  <div class="patternTools">
    <div class="row colorRow">
      <div class="label">
        colors
      </div>

      <div class="inputSection">
        <div 
          class="colorInput"
          v-for="(color, index) in bg.colors"
          :key="index"
          :class='{ activeOption : index < colorCount }'
          :style="index < colorCount
                  ? { transition : 'all .25s ease ' + (index - prevL) * .08 + 's' }
                  : { transition : 'all .25s ease ' + ((prevL - 1) - index) * .08 + 's' }"
        >
          <input
            type="color"
            v-model="bg.colors[index]"
          >
        </div>
      </div>
    </div>

    <div
      class="row"
      v-for='input in filteredSettings'
      :key='input.property'
    >
      <div class="label">
        {{ input.label }}
      </div>

      <div class="inputSection">
        <input
          type="range"
          :min="input.min"
          :max="input.max"
          :step="input.step"
          v-model="bg.src[input.property]"
        >
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: null,
      colorDiff: 0,
      prevL: 0,
      currL: 0,

      settings: [
        {
          property: 'scale',
          label: 'zoom',
          min: '1',
          max: '8',
          step: '.1',
          default: '2',
          value: '2'
        },
        {
          property: 'rotate',
          label: 'rotate',
          min: '0',
          max: '180',
          step: '5',
          default: '0',
          value: '0'
        },
        {
          property: 'opacity',
          label: 'opacity',
          min: '0',
          max: '1',
          step: '.05',
          default: '1',
          value: '1'
        },
        {
          property: 'strokeWidth',
          label: 'stroke',
          min: '.5',
          max: '8',
          step: '.25',
          default: '1',
          value: '1'
        },
      ],
    }
  },





  props: {
    bg: {
      type: Object,
      required: true
    }
  },





  computed: {
    // Number of colors active pattern requires.
    colorCount() {
      return this.bg.src.paths.length + 1;
    },

    // Returns colors for active pattern.
    filteredColors() {
      var filteredColors = [];

      for (var i = 0; i < this.colorCount; i++) {
        filteredColors.push(this.bg.colors[i]);
      }

      return filteredColors;
    },

    // Returns settings needed for active pattern.
    filteredSettings() {
      var filteredSettings = [];

      for (var i = 0; i < this.settings.length; i++) {
        var thisP = this.bg.src,
            thisS = this.settings[i];

        if (thisS.property === 'strokeWidth' && thisP.pathColorType !== 'stroke') {
          continue;
        }
        else {
          filteredSettings.push(thisS);
        }
      }

      return filteredSettings;
    },

    // Returns true if bg
    // editorIsActive() {}
  },





  watch: {
    colorCount() {
      this.prevL = this.currL;

      this.colorDiff = this.prevL - this.colorCount;
      this.currL = this.colorCount;
    }
  },





  methods: {
    // buildPaths(pattern) {
    //   var paths = '';

    //   for (var i = 0; i < pattern.paths.length; i++) {
    //     var p = pattern.paths[i],
    //         pcType = pattern.pathColorType,
    //         pathC = pcType === 'stroke'
    //                 ? "stroke='" + this.bg.colors[1 + i] + "' fill='none'"
    //                 : "stroke='none' fill='" + this.bg.colors[i + 1] + "'",
    //         path = "<path d='" + p + "' stroke-width='" + pattern.strokeWidth + "' " + pathC + " />";

    //     paths += path;
    //   }

    //   return paths;
    // },



    // buildBG() {
    //   var pattern = this.bg.type === 'pattern'
    //                 ? this.bg.src
    //                 : '',
    //       paths = this.buildPaths(pattern),
    //       svg = "<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><defs><pattern id='bgPattern' patternUnits='userSpaceOnUse' width='" + pattern.width + "' height='" + pattern.height + "' patternTransform='scale(" + pattern.scale + ") rotate(" + pattern.rotate + ")'><rect x='0' y='0' width='100%' height='100%' fill='hsla(258,86.3%,34.3%,1)'/>" + paths + "</pattern></defs><rect width='800%' height='800%' fill='url(%23bgPattern)'/></svg>",
    //       encodedSvg = svg.replace(/#/g, '%23'),
    //       uri = 'url("data:image/svg+xml,' + encodedSvg;

    //   return uri;
    // },  
  },





  created() {
    // if (this.bg.type === 'pattern') {
    //   this.activePattern = this.bg.src;
    // }
    // else {
    //   this.activePattern = 
    // }
  }
}
</script>

<style scoped>
  .patternTools {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
    /* height: 80%; */
  }
  
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 3rem;
    font-size: .8rem;
    color: #555;
  }

  .inputSection {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
  }

  .colorRow .inputSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .75rem;
    padding: .5rem 1.25rem;
  }

  .colorInput {
    opacity: 0;
    transform: translateX(200px) rotate(45deg);
  }

  .activeOption {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
</style>
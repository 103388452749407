<template>
  <div class="container">
    
    <canvas 
      ref='canvas'
      class='canvasStyles'
      :height='height'
      :width='width'
    >
    </canvas>

    <div class="topLayer">
      <div class="banner">
        <span @click='statsActive = !statsActive'>particle</span>
        <span @click='settingsActive = !settingsActive'>practice</span>
      </div>

      <div class="menuContainer">
        <div 
          class="statBook"
          :class="{ active : statsActive }"
        >
          <div class="statRow buttonRow">
            <button class="editSettings">
              EDIT
            </button>
          </div>

          <div class="statRow">
            <div class="label"> mouse : </div>
            <div> {{ Math.round((mouse.x / width) * 100) }}, {{ Math.round((mouse.y / height) * 100) }} </div>
          </div>

          <div class="statRow">
            <div class="label"> particles : </div>
            <div> {{ circleArray.length }} </div>
          </div>
          
          <div class="statRow">
            <div class="label"> radius : </div>
            <div> {{ avgRadius }} </div>
          </div>
        </div>

        <div
          class="settings"
          :class="{ active : settingsActive }"
        >
          <div class="settingsRow">
            <span>
              Number of Particles:
            </span>
            
            <div class='statStat'>
              {{ particleCount }}
            </div>

            <input
              type='range'
              min='1'
              max='1000'
              v-model='particleCount'
              @change='initCircles()'
            >
          </div>
        </div>
      </div>    
    </div>

    <!-- <div class="thousand"></div>
    <div class="thousand"></div>
    <div class="thousand"></div>
    <div class="thousand"></div>
    <div class="thousand"></div>
    <div class="thousand"></div> -->

  </div>
</template>





<script>
export default {
  name: '',

  data: function () {
    return {
      // canvas 
      ctx: null,
      canvas: null,
      height: 512,
      width: 512,
      margin: 10,

      // cursor
      cursorActive: false,
      mouse: {
        x: null,
        y: null,
      }, 

      // particles
      circleArray: [],
      particleCount: 200,
      maxRadius: 12,
      minRadius: 2,
      colors: [
        '#9B7EDE', //purple
        '#91C4F2', //blue
        '#B2FFD6', //green
        '#F76F8E', //pink
      ],
      statsActive: false,
      settingsActive: false,
    }
  },



  props: {},


  
  computed: {
    avgRadius: function() {
      var length = this.circleArray.length,
          allRadius = 0,
          avg;

      for (var i = 0; i < length; i++) {
        allRadius += this.circleArray[i].radius 
      }
      avg = (Math.floor(allRadius / length) * 100) / 100;  
      
      return avg;
    },



    canvasWidth() {
      return this.width - this.margin * 2;
    },



    canvasHeight() {
      return this.height  - this.margin * 2;
    },
  },



  methods: {
    // Initialize circle array
    initCircles: function() {
      this.circleArray = [];

      this.eraseCanvas();

      for (var i = 0; i < this.particleCount; i++) {
        var radius = Math.floor((Math.random() * (this.maxRadius - this.minRadius) + this.minRadius) * 100) / 100,
            x = Math.random() * (this.width - radius * 2) + radius,
            y = Math.random() * (this.height - radius * 2) + radius,
            dx = (Math.random() - 0.5),
            dy = (Math.random() - 0.5),
            newCircle = {x: x, y: y, dx: dx, dy: dy, radius: radius};

        this.circleArray.push(newCircle);
        this.drawCircles(newCircle);
      }

      console.log('Loaded ' + this.circleArray.length + ' circles.');
    },

    // Creates circles from circleArray
    drawCircles: function(c) {
      var color = this.colors[Math.floor(Math.random() * 4)],
              x = c.x,
              y = c.y,
         radius = c.radius;

      this.ctx.beginPath();
      this.ctx.arc(x, y, radius, 0, Math.PI * 2, false);
      this.ctx.fillStyle = color;
      this.ctx.fill();
    },
    
    // Erase everything on the canvas.
    eraseCanvas: function() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },

    // Size canvas based on window
    handleResize: function() {
      this.height = window.innerHeight;
      this.width = window.innerWidth;

      // Repaints canvas on the next update cycle
      this.$nextTick(() => {
        this.initCircles();
      })
    },

    // Map the mouse position
    mapMouse: function(event) {
      this.mouse.x = event.x;
      this.mouse.y = event.y;
    },

    toggleSettings: function() {
      this.settingsActive = !this.settingsActive;
    },

    toggleStats: function() {
      this.statsActive = !this.statsActive;
    },
  },



  watch: {
    cursorActive: function() {
      if (this.cursorActive) {
        console.log('Cursor active.');
      } else {
        console.log('Cursor inactive.');
      }
    },
  },



  mounted: function () {
    // Assign canvas ref to data
    this.canvas = this.$refs.canvas;
    this.ctx = this.$refs.canvas.getContext('2d');

    // Set initial size of canvas
    this.handleResize();

    // Add responsive resize
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('mousemove', this.mapMouse);
  },



  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('mousemove', this.mapMouse);
  },
}
</script>





<style scoped>
* {
  box-sizing: border-box;
}

input[type=range] {
  width: 100%;
  background: transparent;
  margin: 11px 0;
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #34D1BF;
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  height: 24px;
  width: 12px;
  background: #ffffff;
  border: none;
  border-radius: 1px;
  box-shadow: 0.5px 0.5px 1px .25px #2A2D34;
  margin-top: -11px;
  -webkit-appearance: none;
  cursor: pointer;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}

.container {
  width: 100%;
  font-family: 'Josefin Sans', Arial, Helvetica, sans-serif;
  /* background-color: #FAFAFF;   */
  overflow-x: hidden;
}

.canvasStyles {
  position: fixed;
  inset: 0;
  z-index: -3;
}

/* gunmetal : #2A2D34 */

.topLayer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.banner {
  color: #F5F5FF;
  font-size: 3em;
  font-weight: bold;
  margin: 0 0 1em 0;
  user-select: none;
}

.banner span {
  min-width: 2em;
  padding: .1em .2em;
  background: #3454D1;
  cursor: pointer;
}

.banner span + span {
  background: #34D1BF;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  transition: opacity .2s ease;
}

.menuContainer > div:hover {
  background: #fff;
  color: #555;
}

.statBook,
.settings {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.75);
  color: rgba(85, 85, 85, 0.75);
  padding: .5em 1.5em .5em .5em;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  transition: all .2s;
}

.statBook {
  border: 2px solid #3454D1;
  margin-left: auto;
  margin-bottom: 2em;
  user-select: none;
}

.settings {
  border: 2px solid #34D1BF;
}

.settingsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: .5rem;
}

.settingsRow span {
  padding: .1em .2em;
  user-select: none;
}

.statRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.statRow .label {
  width: 4.5rem;
  text-align: right;
}

.statRow .label + div {
  width: 5rem;
}

.statStat {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5ch;
  padding: 0 0 0 .5em;
}

.thousand {
  height: 1000px;
  width: 100%;
}

.thousand .tick {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid black;
}
</style>

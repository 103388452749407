<template>
  <div class="imageTools">
    
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: {
        size: '50',
        x: '50',
        y: '50',
        opacity: '1',
      },
    
    }
  },





  props: {
    bg: {
      type: Object,
      required: true
    }
  },





  computed: {
    
  },





  methods: {
    
    
  },





  created() {
   
  }
}
</script>

<style scoped>
  .imageTools {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
  }

</style>
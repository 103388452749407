<template>
  <div class="colorTools">
      
    <div 
      class="colorsPreview" 
      :style="!this.bgIsGradient
              ? {background : buildPathBG('preview')}
              : {background : buildGradientBG('preview')}"
    >
      <div 
        class="dragTrack"
        ref="dragTrack"
      >
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.54 18a2.06 2.06 0 0 1-1.3-.46l-5.1-4.21a1.7 1.7 0 0 1 0-2.66l5.1-4.21a2.1 2.1 0 0 1 2.21-.26 1.76 1.76 0 0 1 1.05 1.59v8.42a1.76 1.76 0 0 1-1.05 1.59 2.23 2.23 0 0 1-.91.2zm-4.86-6l4.82 4V8.09z"/></svg> -->

        <button 
          class="dragThumb"
          v-for='(color, index) in colors'
          :key='index'
          :class='{ active : activeColorIndex === index }'
          @mousedown='initDrag($event, index)'
          :style="bgIsGradient
                  ? { left : `calc(${color.stop}% - 1rem` }
                  : index < (colors.length - 1)
                  ? { left : `calc(${colorBorders[index]}% - 1rem` }
                  : { display : 'none' }"
        >
          <div class="thumbLabel">
            <span v-if='bgIsGradient'>
              {{ Math.round(color.stop) }}
            </span>

            <span v-else>
              {{ Math.round(color.size) }}
            </span>
          </div>
        </button>

        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.46 18a2.23 2.23 0 0 1-.91-.2 1.76 1.76 0 0 1-1.05-1.59V7.79A1.76 1.76 0 0 1 9.55 6.2a2.1 2.1 0 0 1 2.21.26l5.1 4.21a1.7 1.7 0 0 1 0 2.66l-5.1 4.21a2.06 2.06 0 0 1-1.3.46zm0-10v7.9l4.86-3.9z"/></svg> -->
      </div>
    </div>
    
    <div class="row colRow">
      <div class="column colorList">
        <div 
          class="bgColor row"
          v-for="(color, index) in colors"
          :key="index"
        >

          <div class="colorDetails">
            <div class="colorInput">
              <input 
                type="color" 
                v-model='bg.colors[index]'
              />
            </div>

            <div class="colorHex">
              {{ color.color.replace('#', '') }}
            </div>
          </div>

          <div
            class="stop"
            v-if="bgIsGradient"
          >
            {{ Math.round(color.stop) }}
          </div>

          <div
            class="size"
            v-else
          >
            {{ Math.round(color.size) }}
          </div>

          <button 
            class="removeColor flexC"
            :class="{ disabled : colorCount === 1 }"
            @click='removeColor(index)'
          >
            <!-- X icon -->
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg> -->

            <!-- Trash icon -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"/><path d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"/><path d="M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"/></svg>
          </button>
        </div>

        <button 
          class="addColor flexC"
          :class="{ disabled : colorCount === 5 }"
          @click='addColor'
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"/></svg>
        </button>
      </div>

      <div class="column">
        <div class="borderOptions row">
          <div
            v-for="type in borderTypes"
            :key="type.type"
          >
            <input
              type='radio'
              name='borderType'
              :id='type.type + "_bt"'
              :value='type.type'
              v-model='settings.borderType'
              @change='updateBg()'
            >

            <label
              class="borderType 'flexC'"
              :for='type.type + "_bt"'
            >
              <div 
                class="borderIcon"
                v-html="type.icon"
              >

              </div>
            </label>
          </div>
        </div>

        <div class="customBorderSettings">
          <div class="row">
            <div class="label"></div>

            <input
              type='range'
              class=''
              min=''
              max=''
              step=''
              v-model='this.settings.frequency'
            >

            <div class="label"></div>
          </div>

          <div class="row">
            <div class="label"></div>

            <input
              type='range'
              class=''
              min=''
              max=''
              step=''
              v-model='this.settings.contrast'
            >

            <div class="label"></div>
          </div>

          <div class="row">
            <div class="label"></div>

            <input
              type='range'
              class=''
              min=''
              max=''
              step=''
              v-model='this.settings.differential'
            >

            <div class="label"></div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      colorCount: 4,

      colors: [],

      borderTypes: [
        {
          type: 'linearGradient',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M5 17.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zM9 13c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zM3 21h18v-2H3v2zM5 9.5c.83 0 1.5-.67 1.5-1.5S5.83 6.5 5 6.5 3.5 7.17 3.5 8 4.17 9.5 5 9.5zm0 4c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zM9 17c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm8-.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM3 3v2h18V3H3zm14 5.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm0 4c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM13 9c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1z"/></svg>'
        },
        {
          type: 'radialGradient',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM7 9.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"/></svg>'
        },
        {
          type: 'default',
          icon: '<svg version="1.1" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="m40 122a6.0006 6.0006 0 1 0 0 12h176a6.0006 6.0006 0 1 0 0-12z"/></svg>'
        },
        {
          type: 'waves',
          icon: '<svg version="1.1" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="m80.098 57.547c-8.309-0.5624-17.317 3.0346-25.693 10.973-11.168 10.584-22.685 28.607-35.846 56.953a6.0006 6.0006 0 1 0 10.883 5.0547c12.839-27.654 24.073-44.631 33.217-53.297s14.729-9.3007 20.719-6.7207c5.9893 2.58 12.948 10.293 19.588 21.229 6.6396 10.936 13.094 24.789 19.594 38.789s13.046 28.147 20.219 39.961c7.1729 11.814 14.839 21.602 25.1 26.021s22.55 1.5548 33.719-9.0293 22.685-28.607 35.846-56.953a6.0006 6.0006 0 1 0-10.883-5.0547c-12.839 27.654-24.073 44.631-33.217 53.297s-14.729 9.3007-20.719 6.7207-12.948-10.293-19.588-21.229c-6.6396-10.936-13.094-24.789-19.594-38.789s-13.046-28.147-20.219-39.961c-7.1729-11.814-14.839-21.601-25.1-26.021-2.5652-1.105-5.2557-1.7559-8.0254-1.9434z" /></svg>'
        },
        {
          type: 'steps',
          icon: '<svg version="1.1" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="m24 66a6.0006 6.0006 0 0 0-6 6v56a6.0006 6.0006 0 1 0 12 0v-50h92v106a6.0006 6.0006 0 0 0 6 6h104a6.0006 6.0006 0 0 0 6-6v-56a6.0006 6.0006 0 1 0-12 0v50h-92v-106a6.0006 6.0006 0 0 0-6-6z"/></svg>'
        },
        {
          type: 'points',
          icon: '<svg version="1.1" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="m76.271 50.006a6.0006 6.0006 0 0 0-5.1348 2.4805l-52 72a6.0006 6.0006 0 1 0 9.7266 7.0273l47.137-65.266 99.137 137.27a6.0006 6.0006 0 0 0 9.7266 0l52-72a6.0006 6.0006 0 1 0-9.7266-7.0273l-47.137 65.266-99.137-137.27a6.0006 6.0006 0 0 0-4.5918-2.4805z"/></svg>'
        }
      ],

      settings: {
        borderType: 'default', 
        x: '',
        y: '',
        rotate: '',
        radius: '',

        frequency: 5,
        contrast: .2,
        differential: .5
      },

      prevH: 40,
      prevW: 480,

      // REVERSE ORDER / FLIP ICON
      // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.09 19h12l-1.3 1.29a1 1 0 0 0 1.42 1.42l3-3a1 1 0 0 0 0-1.42l-3-3a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.42l1.3 1.29h-12a1.56 1.56 0 0 1-1.59-1.53V13a1 1 0 0 0-2 0v2.47A3.56 3.56 0 0 0 6.09 19z"/><path d="M5.79 9.71a1 1 0 1 0 1.42-1.42L5.91 7h12a1.56 1.56 0 0 1 1.59 1.53V11a1 1 0 0 0 2 0V8.53A3.56 3.56 0 0 0 17.91 5h-12l1.3-1.29a1 1 0 0 0 0-1.42 1 1 0 0 0-1.42 0l-3 3a1 1 0 0 0 0 1.42z"/></svg>

      activeColorIndex: null,
      dragProp: null,
      dragStart: null,
      startingVal: null,
      nextStartingVal: null,
      containerWidth: null,
      minSize: 8,
    }
  },




  props: {
    bg: {
      type: Object,
      required: true
    }
  },





  computed: {
    gradient() {
      var gradient = 'linear-gradient(90deg',
          cnt = this.colorCount;

      for (var i = 0; i < cnt; i++) {
        var thisStop = this.colors[i].stop, 
            thisColor = this.colors[i].color,
            thisStart = i * this.rnd(i * (100 / cnt)),
            thisEnd = i === cnt - 1 ? 100 : this.rnd(i + 1 * (100 / cnt)),
            str;

        if (this.borderType === 'gradient') {
          str = ', ' + thisColor + ' ' + thisStop + '%';
        }
        else {
          str = ', ' + thisColor + ' ' + thisStart + '%, ' + thisColor + ' ' + thisEnd + '%';
        }
        gradient += str;
      }

      gradient += ')';

      return {'--gbg' : gradient};
    },



    // Returns true if background is a gradient.
    bgIsGradient() {
      var bool = this.settings.borderType === 'linearGradient' 
                 || this.settings.borderType === 'radialGradient'
                 ? true
                 : false;

      return bool;
    },



    colorBorders() {
      var total = 0,
          borders = [];

      for (var i = 0; i < this.colors.length; i++) {
        var nextB = i === (this.colors.length - 1)
                    ? 100
                    : total += this.colors[i].size;

        borders.push(nextB);
      }

      return borders;
    },

    // svgPaths() {
    //   var paths = [],
    //       container = document.querySelector('.svgPreview').getBoundingClientRect(),
    //       cntrW = container.width,
    //       cntrH = container.height,
    //       prevEnd = 0;

    //   for (var i = 0; i < this.colors.length; i++) {
    //     var p = {},
    //         c = this.colors[i],
    //         cW = (c.width / cntrW) * 100,
    //         cC = c.color;
            
    //     p.d = 'M ' + prevEnd + ',0 ' + 'L ';
    //     p.fill = cC;
    //   }

    //   return paths;
    // }
  },





  methods: {
    updateBg() {
      var bgStr = this.bgIsGradient
                  ? this.buildGradientBG()
                  : this.buildPathBG();

      var src = Object.assign({}, this.settings);

      src.css = bgStr;

      console.log(src);

      this.$emit('updateBackground', src, 'color');
    },



    addColor() {
      var newColor = {};

      this.colorCount++;

      newColor.color = this.bg.colors[this.colorCount - 1];
      newColor.size = 100 / this.colorCount;
      newColor.stop = 100;

      for (var i = 0; i < this.colors.length; i++) {
        var c = this.colors[i],
            scale = (100 - newColor.size) / 100;

        c.size = this.rnd(c.size * scale);
        c.stop = this.rnd(c.stop * scale);
      }

      this.colors.push(newColor);
      this.updateBg();
    },



    removeColor(i) {
      var rColor = this.bg.colors.splice(i, 1),
          rSize = 100 - this.colors[i].size;

      this.bg.colors.push(rColor[0]);

      this.colors.splice(i, 1);

      this.colorCount--;

      for (var n = 0; n < this.colors.length; n++) {
        var adjustedSize = this.rnd((this.colors[n].size / rSize) * 100);

        this.colors[n].size = adjustedSize;
        this.colors[n].color = this.bg.colors[n];
      }
      
      this.updateBg();
    },



    initDrag(e, i) {
      this.activeColorIndex = i;
      this.dragProp = !this.bgIsGradient
                      ? 'size'
                      : 'stop',
      this.dragStart = this.rnd(e.pageX);
      this.containerWidth = this.$refs.dragTrack.clientWidth;
      this.startingVal = this.colors[i][this.dragProp];
      this.nextStartingVal = i === (this.colors.length -1)
                             ? 100
                             : this.colors[i + 1][this.dragProp];

      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('mouseup', this.endDrag);

      document.body.style.cursor = 'grabbing';
    },



    onDrag(e) {
      var i = this.activeColorIndex,
          thisC = this.colors[i],
          nextC = this.dragProp === 'size'
                  ? this.colors[i + 1]
                  : null,
          maxPerc = this.dragProp === 'size'
                    ? this.startingVal + (this.nextStartingVal - this.minSize)
                    : i !== (this.colors.length - 1)
                    ? this.nextStartingVal - this.minSize
                    : 100,
          minPerc = i !== 0
                    ? (this.colors[i - 1].stop + this.minSize)
                    : 0,
          movedPx = this.rnd(e.pageX) - this.dragStart,
          movedPerc = this.rnd((movedPx / this.containerWidth) * 100),
          newPerc,
          nextNewPerc;


      this.movedPx = this.rnd(e.pageX) - this.dragStart;
      this.movedPerc = this.rnd((movedPx / this.containerWidth) * 100);

      newPerc = this.rnd(this.startingVal + movedPerc);

      if (this.dragProp === 'size') {
        thisC[this.dragProp] = this.limitNumberWithinRange(newPerc, this.minSize, maxPerc);
        nextNewPerc = this.rnd(this.nextStartingVal - movedPerc);
        nextC[this.dragProp] = this.limitNumberWithinRange(nextNewPerc, this.minSize, maxPerc);
      }
      else {
        thisC[this.dragProp] = this.limitNumberWithinRange(newPerc, minPerc, maxPerc);
      }
    },



    endDrag() {
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('mouseup', this.endDrag);

      document.body.style.cursor = "initial";

      // this.activeColorIndex = null;
      // this.dragProp = null;
      // this.startVal = null;
      // this.nextStartingVal = null;
      // this.dragStart = null;

      this.updateBg();
    },



    buildGradientBG(el) {
      var stops = '',
          bgH = el !== 'preview'
                ? window.innerHeight
                : this.prevH,
          bgW = el !== 'preview'
                ? window.innerWidth
                : this.prevW,
          type = this.settings.borderType,
          pos = type === 'radialGradient' && el === 'preview'
                ? " cx='0%' cy='50%' r='120%'"
                : type === 'radialGradient' && el !== 'preview'
                ? " cx='" + this.settings.x + "%' cy='" + this.settings.y + "%' r='" + this.settings.radius + "%'"
                : "",
          transform = type === 'linearGradient' && el !== 'preview'
                      ? "gradientTransform='rotate(" + this.settings.rotate + ", .5, .5)'"
                      : "",
          svg,
          encoded,
          uri;

      for (var i = 0; i < this.colors.length; i++) {
        var c = this.colors[i],
            stopStr = '';

        stopStr = "<stop offset='" + c.stop + "%' stop-color='" + this.bg.colors[i] + "' />";

        stops += stopStr;
      }

      svg = "<svg height='" + bgH + "' width='" + bgW + "' xmlns='http://www.w3.org/2000/svg'>" +
              "<defs>" +
                "<" + type + pos + " id='bg' gradientUnits='userSpaceOnUse' " + transform + " transform-origin='center'>" +
                  stops +
                "</" + type + ">" +
              "</defs>" +
              "<rect height='" + bgH + "' width='" + bgW + "' fill='url(%23bg)' />" +
            "</svg>";

      encoded = svg.replace(/#/g, '%23');
      uri = `url("data:image/svg+xml, ${encoded}")`;

      return uri;
    },



    buildPathBG(el) {
      var paths = '',
          h = el !== 'preview'
              ? window.innerHeight
              : this.prevH,
          w = el !== 'preview'
              ? window.innerWidth
              : this.prevW,
          angle = el !== 'preview'
                  ? "transform='rotate(" + this.settings.rotate + ")'"
                  : "",
          prevEnd = 0,
          svg,
          encoded,
          uri;

      for (var i = 0; i < this.colors.length; i++) {
        var thisColor = this.bg.colors[i],
            thisSize = this.rnd((w / 100) * this.colors[i].size),
            thisEnd = w,
            thisPath = "",
            customBorder = i === 0 || this.settings.borderType === 'default'
                           ? ""
                           : this.getBorder(i, prevEnd, w, h);

        thisPath = "<path fill='" + thisColor +
                   "' d='M" + prevEnd + ",0 " +
                   "L" + thisEnd + ",0 " +
                   thisEnd + "," + h + " " +
                   prevEnd + "," + h +

                  customBorder +

                  "z' />";

        paths += thisPath;
        prevEnd += thisSize;
      }

      svg = "<svg height='100%' xmlns='http://www.w3.org/2000/svg' " + angle + ">"
              + paths +
            "</svg>";
      encoded = svg.replace(/#/g, '%23');
      uri = `url("data:image/svg+xml, ${encoded} ")`;

      // console.log(svg);

      return uri;
    },



    getBorder(index, border, svgW, svgH) {
      var step = this.rnd(svgH / this.settings.frequency),
          R = this.rnd((svgW / 100) * ((this.colors[index].size * .4) * this.settings.contrast)),
          L = this.rnd((svgW / 100) * ((this.colors[index - 1].size * .4) * this.settings.contrast)),
          y = svgH,
          pts = this.settings.borderType !== 'waves'
                ? ''
                : ' S';

      if (this.settings.borderType === 'waves') {
        R = R * .75;
        L = L * .75;
      }

      for (var i = 0; i < this.settings.frequency; i++) {
        var max = i % 2 === 1
                  ? border - L + (L * this.settings.differential)
                  : border + R,
            min = i % 2 === 1
                  ? border - L
                  : border + R - (R * this.settings.differential),
            x = this.getRandom(min, max),
            str = this.settings.borderType === 'points'
                  ? ' ' + x + ',' + this.rnd(y -= step)
                  : this.settings.borderType === 'steps'
                  ? ' ' + x + ',' + this.rnd(y) + ' ' + x + ',' + this.rnd(y -= step)
                  : this.settings.borderType === 'waves'
                  ? ' ' + x + ',' + this.rnd(y - (step / 2)) + ' ' + x + ',' + this.rnd(y -= step)
                  : '';
                  
        pts += str;
      }

      return pts;
    },



    getRandom(min, max) {
      var r;

      min = Math.ceil(min);
      max = Math.floor(max);
      r = Math.floor(Math.random() * (max - min + 1) + min);

      return this.rnd(r);
    },



    rnd(x) {
      return Math.round(x * 100) / 100;
    },



    limitNumberWithinRange(val, min, max) {
      return Math.min(Math.max(val, min), max);
    },



    // Converts colors in hexidecimal format into RGB.
    hexToRGB(hexColor) {
      var R = 0,
          G = 0,
          B = 0;

      // Test for 3-digit hex format.
      if (hexColor.length == 4) {
        R = '0x' + hexColor[1] + hexColor[1]; 
        G = '0x' + hexColor[2] + hexColor[2];
        B = '0x' + hexColor[3] + hexColor[3];
      }
      // Else 6-digit.
      else if (hexColor.length == 7) {
        R = '0x' + hexColor[1] + hexColor[2]; 
        G = '0x' + hexColor[3] + hexColor[4];
        B = '0x' + hexColor[5] + hexColor[6];
      }

      // Use unary plus operator to convert to number.
      return 'rgb(' + +R + ',' + +G + ',' + +B + ')';
    },



    // Takes color in rgb format and returns brightness.
    // Based on http://www.w3.org/TR/AERT#color-contrast
    // A brightness difference under 125 between text and background is insufficient.
    getBrightness(color) {
      var hexRgx = /^#([0-9A-F]{3}){1,2}$/i,
          splitChar,
          rgbArr,
          brightness;

      if (hexRgx.test(color)) {
        color = this.hexToRGB(color);
      }

      splitChar = color.indexOf(',') > -1 // If comma detected,
                  ? ',' // split RGB at comma.
                  : ''; // Else, split RGB at whitespace.

      // Turn rgb(r,g,b) into [r, g, b].
      rgbArr = color.substr(4).split(')')[0].split(splitChar);

      brightness = Math.round(((parseInt(rgbArr[0]) * 299) + (parseInt(rgbArr[1]) * 587) + (parseInt(rgbArr[2]) * 114)) / 1000);

      return brightness;
    },
  },





  created() {
    var prevStop = 0,
        total = 0,
        lastIndex = this.colorCount - 1;

    // init colors array.
    for (var i = 0; i < this.colorCount; i++) {
      var color = {};

      color.color = this.bg.colors[i];
      color.stop = i === 0
                   ? 0
                   : i === lastIndex
                   ? 100
                   : this.rnd(prevStop + (100 / lastIndex)),
      color.size = i === lastIndex
                   ? this.rnd(100 - total)
                   : this.rnd(100 / this.colorCount);

      this.colors.push(color);

      prevStop = color.stop;
      total += color.size;
    }
  }
}
</script>

<style scoped>
  .colorTools {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
  }

  .colorsPreview {
    position: relative;
    display: flex;
    height: 2.5rem;
    width: 30rem;
    background: var(--gbg);
    box-shadow: 0 0 2px .75px #aaa;
    margin-bottom: 2.5rem;
  }

  .dragTrack {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .dragThumb {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    height: 0rem; 
    width: 0rem;
    background: transparent;
    z-index: 1;
    padding: 0;
    border: .5rem solid transparent;
    border-radius: 5px;
    border-top: none;
    cursor: grab;
    user-select: none;
  }

  .dragThumb:active {
    /* transform: scale(1.2); */
    /* box-shadow: 0 0 2px .75px #aaa; */
    cursor: grabbing;
    transform-origin: center;
    transition: box-shadow .25s, transform .25s;
  }

  .dragThumb span {
    position: absolute;
    bottom: -1.75rem;
    font-size: .8rem;
    color: #555;
    padding: .25rem;
  }

  .colRow {
    gap: 2rem;
    padding: .25rem .5rem;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  .column + .column {
    width: 65%;
  }

  .colorList {
    gap: .5rem;
    color: #667;
    /* font-weight: bold; */
  }

  .colorDetails {
    display: flex;
    align-items: center;
    gap: .25rem;
  }

  .colorList button {
    height: 1.75rem;
    width: 1.75rem;
    background: transparent;
    padding: 0;
    border: none;
    border-radius: 3px;
    /* box-shadow: 0px 0px 2.25px .5px #ccc; */
    /* box-shadow: 0px 0px 2px .25px #aaa; */
    margin: 0;
    cursor: pointer;
    transition: all .2s ease;
  }

  .colorList button:hover {
    box-shadow: 0px 0.75px 2.75px 0.25px #aaa;
  }

  .colorList button svg {
    position: absolute;
    height: 1.1rem;
    width: 1.1rem;
    fill: #555;
    opacity: .85;
    transform-origin: center;
    transition: all .2s ease;
  }

  .colorList button:hover svg {
    opacity: 1;
    transform: scale(1.1);
  }

  .colorList button:active svg {
    opacity: 1;
    transform: scale(.95);
  }

  .bgColor {
    align-items: center;
    gap: .75rem;
    font-size: .8rem;
  }

  .colorHex {
    width: 7ch;
  }

  .colorList button.removeColor {
    margin-left: auto;
  }
  
  .colorList button.addColor {
    margin: auto;
  }

  .disabled {
    opacity: .6;
    pointer-events: none;
  }

  .borderType {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    opacity: .7;
    transition: all .25s ease;
  }

  .borderIcon >>> svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: #555;
  }
</style>

<template>
  <div class='gameContainer'>
    
    <canvas
      ref='gameCanvas'
      :width='this.canvasWidth'
      :height='this.canvasHeight'
    >
    </canvas>

    <div class="statContainer">
      <div class="stat">
        <span>
          {{ Math.round(this.player.position.x) }}
        </span>
        ,
        <span>
          {{ Math.round(this.player.position.y) }}
        </span>
      </div>
    </div>

  </div>
</template>





<script>
export default {
  name: 'Game',

  data() {
    return {
      // Window Dimensions
      w: {
        height: null,
        width: null
      },

      canvas: null,
      canvasHeight: 400,

      player: {
        position: {
          x: 260,
          y: 110
        },

        velocity: {
          x: 0,
          y: .25
        },

        width: 20,
        height: 20,
        color: '#2A5ADF' // blue
      },

      // enemy: {
      //   x: 50,
      //   y: 50,
      //   vx: 5,
      //   vy: 2,
      //   radius: 15,
      //   color: '#FF0000' // red
      // },

      platforms: [
        {
          position: {
            x: 100,
            y: 280
          },
          width: 200,
          height: 20,
          color: '#011C27'
        },
      ],

      keys: {
        right: {
          pressed: false
        },
        left: {
          pressed: false
        }
      },

      gravity: .25,

      // pink #FC60A8
      // purple #7A28CB

      // Dark Grey #3E3E3D
      // Bright Green #0AFF47
      // Bright Blue #0ABEFF
    }
  },



  computed: {
    canvasWidth() {
      return Math.floor(this.w.width * .8);
    }
  },



  methods: {
    // Draws Canvas
    draw() {
      var c = this.canvas,
          pos = this.player.position;

      // Draw Player
      c.fillStyle = this.player.color;
      c.fillRect(pos.x, pos.y, this.player.width, this.player.height);

      // Draw Enemy
      // c.beginPath();
      // c.arc(this.enemy.x, this.enemy.y, this.enemy.radius, 0, Math.PI * 2, true);
      // c.closePath();
      // c.fillStyle = this.enemy.color;
      // c.fill();

      // Draw Platform
      c.fillStyle = this.platforms[0].color;
      c.fillRect(this.platforms[0].position.x, this.platforms[0].position.y, this.platforms[0].width, this.platforms[0].height);
    },


    animate() {
      var xPos = this.player.position.x,
          right = this.keys.right.pressed,
          left = this.keys.left.pressed;

      requestAnimationFrame(this.animate);
      this.canvas.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      this.update();

      if (right && xPos < this.canvasWidth * .75 ) {
        this.player.velocity.x = 2.5;
      }
      else if (left && xPos > this.canvasWidth * .25) {
        this.player.velocity.x = -2.5;
      }
      else {
        this.player.velocity.x = 0;

        if (right) {
          this.platforms[0].position.x -= 5;
        }
        else if (left) {
          this.platforms[0].position.x += 5;
        }
      }

      // Platform collision detection.
      // TURN INTO COMPUTED
      if (this.player.position.y + this.player.height <= this.platforms[0].position.y &&
          this.player.position.y + this.player.height + this.player.velocity.y > this.platforms[0].position.y &&
          this.player.position.x + this.player.width >= this.platforms[0].position.x &&
          this.player.position.x <= this.platforms[0].position.x + this.platforms[0].width) {
        this.player.velocity.y = 0;
      }
    },


    update() {
      var p = this.player;

      this.draw();

      p.position.y += p.velocity.y;
      p.position.x += p.velocity.x;

      if (p.position.y + p.height + p.velocity.y <= this.canvasHeight) {
        p.velocity.y += this.gravity;
      }
      else {
        p.velocity.y = 0;
      }
    },


    handleKeyDown(event) {
      // var v = this.player.velocity;

      switch (event.keyCode) {
        case 65:
          this.keys.left.pressed = true;
          // v.x = 5;
          break;
        case 83:

          break;
        case 68:
          this.keys.right.pressed = true;
          // v.x = 5;
          break;
        case 87:
          // v.y -= 4;
          break;
      }
    },


    handleKeyUp(event) {
      var v = this.player.velocity;

      switch (event.keyCode) {
        case 65:
            this.keys.left.pressed = false;
          break;
        case 83:
          break;
        case 68:
            this.keys.right.pressed = false;
          break;
        case 87:
          v.y -= 5;
          break;
      }
    },


    // Updates window dimensions on resize.
    handleResize() {
      this.w.height = window.innerHeight;
      this.w.width = window.innerWidth;

      this.$nextTick(() => {
        this.draw();
      })
    }
  },



  mounted() {
    var canvas = this.$refs.gameCanvas,
        ctx = canvas.getContext('2d');

    this.canvas = ctx;
    this.handleResize();
    this.animate();

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);

    // keycodes
    // w:87, a:65, s:83, d:68
    // Keycode event in vue: @keyup.87
  },



  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  }
}
</script>





<style scoped>
  canvas {
    border: 1px solid grey;
  }

  .gameContainer {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2em; */
  }

  .statContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
    width: 88%;
    padding: .25em;
  }

  .stat {
    display: flex;
  }

  .stat span {
    display: flex;
    justify-content: center;
    width: 2.25em;
  }
</style>

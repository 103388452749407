<template>
  <div class="projectsPage">
    <div class="projectContainer">
      <div
        class="optionWrapper"
        v-for='project in projects'
        :key='project.name'
      >
        <button
          class="projectOption"
          @click='selectProject(project)'
        >
          {{ project.name }}
        </button>
      </div>
    </div>

    <div class="logoPreview">
      
    </div>

    <div class="projectViewer">
      <sideScroller 
        v-if='activeProject === "sideScroller"'
      />

      <bgGen
        v-if='activeProject === "bgGen"'
      />

      <particles
        v-if='activeProject === "particles"'
      />
    </div>

  </div>
</template>

<script>
import SideScroller from '@/components/sideScroller.vue';
import bgGen from '@/components/bgGen/bgGen.vue';
import Particles from '../components/particles.vue'

export default {
  name: 'Projects',





  data() {
    return {
      activeProject: '',
      
      projects: [
        {
          name: 'sideScroller'
        },
        {
          name: 'bgGen'
        },
        {
          name: 'particles'
        }
      ],
    }
  },





  components: {
    SideScroller,
    bgGen,
    Particles
  },





  methods: {
    selectProject(project) {
      this.activeProject = project.name;
    }
  }
}
</script>







<style scoped>
  .projectsPage {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
  }

  .projectContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    height: 5rem;
    padding: 1rem 4rem;
  }

  .projectViewer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 5rem);
  }

  .projectOption {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 2.5rem;
    background: #fff;
    color: #555;
    border: none;
    box-shadow: 0px 0px 2px .75px #ccc;
    cursor: pointer;
  }
</style>